import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { Stack, H, P } from '../components/system';
import { Container } from '../components/layoutComponents';
import beerFinderLogo from '../images/beer-finder-logo-in-box--white.svg';
import styled from 'styled-components';
import { InternalLink } from '../components/Links';

function SuccessPage() {
  return (
    <Layout>
      <SEO
        title={'Thank you for your payment'}
        meta={{ name: 'robots', content: 'noindex' }}
      />
      <div
        css={`
          min-height: 100vh;
          color: white;
          background: ${p => p.theme.colors.black2};
          display: flex;
          align-items: center;
          padding-top: 60px;
          padding-bottom: 80px;
        `}
      >
        <Container>
          <center>
            <Stack lg>
              <InternalLink noStyles to="/">
                <img
                  src={beerFinderLogo}
                  alt="Beer Finder Logo"
                  css={`
                    width: 180px;
                  `}
                />
              </InternalLink>

              <div>
                <strong>
                  <H as="h1" size={['lg', 'xl']} textColor="greyLight">
                    Thank you for signing up 🍻
                  </H>
                </strong>
              </div>
              <ContentBlock>
                <Stack xl>
                  <P size={['md', 'lg']} textColor="greyLight">
                    We are excited to have you on board as a partner, and we
                    looking forward to putting your brand in front of thousands
                    of beer enthusiasts who are using Beer Finder to make their
                    next purchase.
                  </P>
                  <P size={['lg', 'lg']} textColor="greyLight">
                    <strong>
                      We will update the site within 24 hours to reflect your
                      new plan.
                    </strong>
                  </P>
                  <P size={['md', 'md']} textColor="greyLight">
                    If you have any questions, or you don't see your listings
                    after this time, please don't hesitate to reach out to us
                    directly at{' '}
                    <a
                      css={`
                        color: ${p => p.theme.colors.greyLight};
                        text-decoration: underline;
                      `}
                      href="mailto:team@beerfinder.ca?subject=Post-payment question"
                    >
                      team@beerfinder.ca
                    </a>
                    .
                  </P>
                </Stack>
              </ContentBlock>
            </Stack>
          </center>
        </Container>
      </div>
    </Layout>
  );
}

export default SuccessPage;

const ContentBlock = styled.div`
  max-width: 100%;

  @media (min-width: 800px) {
    max-width: 940px;
  }
`;
